import React, {useEffect, useState} from 'react'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Layout from '../components/Layout'
import {Container, Row, Col} from "react-bootstrap"

const NotSupported = () => {

    useEffect(()=>{
        if (!window.document.documentMode) {
            // Do IE stuff
            window.location.href = "/"
        }
    }, [])
    return (
        <Layout>
            <SEO title="Browser not suppported | " />
            <div className="mt4">
                <Container>
                    <Row>
                        <Col>
                            <h1>Sorry!</h1>
                            <h2>Internet  Explorer &trade; is not supported</h2>
                            <h4>Please visit again this page using one of the following recommended modern browsers:</h4>
                            <ul>
                                <li><h6><a href="https://www.google.com/intl/en_us/chrome/">Google Chrome &trade;</a></h6></li>
                                <li><h6><a href="https://www.mozilla.org/en-US/firefox/new/">Mozilla Firefox &trade;</a></h6></li>
                                <li><h6><a href="https://support.apple.com/en-us/HT204416/">Safari for Mac OS &trade;</a></h6></li>
                                <li><h6><a href="https://www.opera.com/download/">Opera &trade;</a></h6></li>
                                <li><h6><a href="https://www.microsoft.com/en-us/edge/">Microsoft Edge &trade;</a></h6></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </Layout>
    )
}

export default NotSupported
